import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
// import News from './Pages/News';
// import ProductPage from "./pages/ProjectPage";
import Contact from "./components/Common/Contact/Contact";
// import error404 from "./Pages/404";
import ServicePage from "./pages/Servicepage";
import AboutPage from "./pages/AboutPage";
import OurProjects from "./pages/our-projects";
// import ServiceDetail from "./Pages/ServiceDetail";
// import ExpreriencePage from "./Pages/ExperiencePage";
import ExperiencePage from "./pages/ExperiencePage";
import InvestorRelationPage from "./pages/InvestorRelationsPage";
import RiyadhPage from "./pages/RiyadhPage";
import JeddahPage from "./pages/JeddahPage";
import BlogDetails from "./pages/Blog";
import ScrollToTop from "./scroll-top";


class App extends Component {
  componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  render() {
    let lang = localStorage.getItem('i18nextLng');
    document.body.classList.add(lang == 'ar' ? 'rtl' : 'ltr')

    return (
      <Router>
        {/* <Switch> */}
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={AboutPage} />
          {/* <Route path="/news" component={News} /> */}
          <Route path="/contact" component={Contact} />
          {/* <Route
            path="/product/:id"
            render={(props) => <ProductPage {...props} />}
          /> */}
          
          <Route path="/services" component={ServicePage} />
          <Route path="/our-projects" component={OurProjects} />
          <Route path="/experience" component={ExperiencePage} />
          <Route path="/experience-riyadh" component={RiyadhPage} />
          <Route path="/experience-jeddah" component={JeddahPage} />
        
          <Route path="/investor-relations" component={InvestorRelationPage} />
          <Route path="/blog" component={BlogDetails}/>
          {/* <Route path="/service-detail" component={ServiceDetail} /> */}
          {/* <Route component={Home}/> */}
        </div>
        {/* </Switch> */}
        <ScrollToTop />

      </Router>
    );
  }
}

export default App;
