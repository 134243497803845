import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Trans } from "react-i18next";

export default class Title extends Component {
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      let element = document.getElementById(this.props.title);
      element.classList.remove("title");
      element.classList.add("title1");
    }
  };
  render() {
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        offset={{ top: 10 }}
        delayedCall
      >
        <h2
          id={this.props.title}
          className="title"
          style={{ color: this.props.color === "white" ? "#ffffff" : "#000" }}
        >
          <Trans>{this.props.title}</Trans>
        </h2>
      </VisibilitySensor>
    );
  }
}
