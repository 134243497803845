import React from "react";
import Fade from "react-reveal/Fade";
import Title from "../Title";
import "./TeamCard.scss";
import "./TeamButton.scss";
import { Trans } from "react-i18next";

export default function TeamDetails() {
  const Team = [
    {
      name: "mrHassan",
      designation: "Chairman",
      image: "image.JPG",
      src: "/assets/images/team/1.jpg",
    },
    {
      name: "NaderAlamri",
      designation: "ExecutivePartner",
      image: "Nader.JPG",
      src: "/assets/images/team/Nader.JPG",
    },
    {
      name: "FaisalBouzo",
      designation: "ExecutivePartner",
      image: "Faisal.png",
      src: "/assets/images/team/Faisal.png",
    },
    {
      name: "TareqAlAjlani",
      designation: "ExecutivePartner",
      image: "Tareq.png",
      src: "/assets/images/team/Tareq.png",
    },
  ];
  return (
    <div className="rn-service-details ptb--60 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
              <Fade up small>
                {/* <h2 className="title">Chairman of the Board of Directors</h2> */}
                <Title title={"BoardDirectors"} />
                {/* <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration.
                </p> */}
              </Fade>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="service-details-inner">
              <div className="inner">
                {/* Start Single Area */}
                <div className="row sercice-details-content  align-items-center ptb--30">
                  <div className="col-md-1"></div>
                  <div className="col-12 col-md-10 text-center">
                    <img
                      src={Team[0].src}
                      alt="Service Images"
                    />
                     <div className="details mt_md--30 mt_sm--30">
                      <Fade up small>
                        <h4 className="title brown-color">
                          <Trans>
                          {Team[0].name}
                          </Trans>
                        </h4>
                        <h5 className="title green-color">
                          <Trans>
                          {Team[0].designation}
                          </Trans>
                        </h5>
                      </Fade>
                      <Fade up small>
                        <p>
                          <Trans>
                          {'About04'}
                          </Trans>
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                        <Trans>
                          {'About05'}
                          </Trans>
                        
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                        <Trans>
                          {'About06'}
                          </Trans>
                        </p>
                      </Fade>
                      <br />
                      <br />
                      <Fade up small>
                        <p>
                        <Trans>
                          {'About07'}
                          </Trans>
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                        <Trans>
                          {'About08'}
                          </Trans>
                        </p>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  
                  {/* <div className="col-lg-4 md-4 sm-12">
                    <div className="thumb">
                      <img
                        className="w-100"
                        src={Team[0].src}
                        alt="Service Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 md-8 sm-12">
                    <div className="details mt_md--30 mt_sm--30">
                      <Fade up small>
                        <h4 className="title">{Team[0].name}</h4>
                        <h5 className="title">{Team[0].designation}</h5>
                      </Fade>
                      <Fade up small>
                        <p>
                          Dear visitors of First Avenue for Real Estate
                          Development’s webpage.
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                          On behalf of the company’s board of directors, I would
                          like to welcome you on the company’s webpage, which we
                          aim to make as a portal to communicate with our
                          respected clients easily and directly. This portal
                          will answer all your questions and inquiries and will
                          explain the objectives and aspirations the company
                          strives to achieve, by adopting an ambitious strategy
                          that takes advantage of new opportunities that will
                          maximize the return on our shareholders’ investments
                          from a wide variety of projects. At First Avenue for
                          real estate development, we look forward to the future
                          by carefully planning our steps that aim to actively
                          contribute to the development of the real estate
                          sector in the Kingdom of Saudi Arabia, through
                          enhancing creativity and employing experienced and
                          competent professionals, who work based on a clear
                          vision that focus on trust, transparency, and
                          integrity and inspired by the Kingdom’s Vision 2030.
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                          I invite you to browse through the website’s different
                          sections and I am confident that you will find answers
                          to key questions that you might have. We will be happy
                          with your interaction, and it will be our pleasure
                          answer your inquires and receive your suggestions.
                        </p>
                      </Fade>
                    </div>
                  </div> */}
                </div>

                {/* <div className="row sercice-details-content  align-items-center ptb--30">
                  <div className="col-lg-8 md-8 sm-12">
                    <div className="details mt_md--30 mt_sm--30">
                      <Fade up small>
                        <h4 className="title">{Team[1].name}</h4>
                        <h5 className="title">{Team[1].designation}</h5>
                      </Fade>
                      <Fade up small>
                        <p>
                          Faisal Bouzo, a Saudi national and holder of the
                          Business Administration degree – with Emphasis on
                          International Management & Entrepreneurship – from the
                          American University of Beirut, is identified through
                          his comprehensive managerial skills and his hands-on
                          involvement in all C&P projects. Going the extra mile
                          through closely supervising projects from inception to
                          completion.
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                          Faisal plays an integral role in running smooth
                          operations and asserting highest quality into the
                          execution, while simultaneously guaranteeing client
                          satisfaction, by meeting and exceeding their
                          expectations, Faisal Bouzo has been ranked 64 on the
                          2017 Construction Week Power 100 list most influential
                          individuals within the Middle East construction
                          sector.
                        </p>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-lg-4 md-4 sm-12">
                    <div className="thumb">
                      <img
                        className="w-100"
                        src={Team[1].src}
                        alt="Service Images"
                      />
                    </div>
                  </div>
                </div>

                <div className="row sercice-details-content  align-items-center ptb--30">
                  <div className="col-lg-4 md-4 sm-12">
                    <div className="thumb">
                      <img
                        className="w-100"
                        src={Team[2].src}
                        alt="Service Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 md-8 sm-12">
                    <div className="details mt_md--30 mt_sm--30">
                      <Fade up small>
                        <h4 className="title">{Team[2].name}</h4>
                        <h5 className="title">{Team[2].designation}</h5>
                      </Fade>
                      <Fade up small>
                        <p>
                          Tarek Ajlani, a Saudi National with a rich background
                          in creative ideas and techniques, nurtured and
                          sculpted through his education at Virginia Tech (where
                          he earned the Master’s Degree in Architecture – with
                          Emphasis on Building Technology), started his
                          professional journey at SOM, Chicago, and then
                          partnered with Faisal Bouzo to establish C&P.
                        </p>
                      </Fade>
                      <Fade up small>
                        <p>
                          Tarek is co-managing the company and leading the
                          design and engineering team. He is also responsible
                          for conceptualizing projects and laying down integral
                          insights into the execution.
                        </p>
                      </Fade>
                    </div>
                  </div>
                </div> */}
                {/* <div className="container sercice-details-content ptb--40 align-items-center">
                  <div
                    className="card col-lg-12"
                    style={{ maxHeight: "450px" }}
                  >
                    <div className="row">
                      <div className="col-lg-3" style={{ padding: 0 }}>
                        <img
                          src={Team[0].src}
                          alt="Avatar"
                          style={{ maxHeight: "450px", opacity: 1 }}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="details mt_md--30 mt_sm--30 col-lg-12">
                            <h4 className="title">{Team[0].name}</h4>
                            <h5 className="title">{Team[0].designation}</h5>
                            <p>
                              Nader Alamri is the Executive Founder of 1st
                              Avenue. Nader is responsible for First Avenue’s
                              finances, operations, contracting, governance,
                              corporate policies and business development with
                              external partners.
                            </p>
                          </div>
                          <div className="col-lg-12">
                            <a className="horizontal" href="/">
                              <span className="text">Know More &#8594;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Area */}

                {/* Start Single Area */}
                {/* <div className="row sercice-details-content ptb--40 align-items-center">
                  <div className="col-lg-8 md-8 sm-12 col-12 order-2 order-lg-1">
                    <div className="details mt_md--30 mt_sm--30">
                      <h4 className="title">{Team[1].name}</h4>
                      <h5 className="title">{Team[1].designation}</h5>
                      <p>
                        Faisal Bouzo, a Saudi national and holder of the
                        Business Administration degree – with Emphasis on
                        International Management& Entrepreneurship – from the
                        American University of Beirut, is identified through his
                        comprehensive managerial skills and his hands-on
                        involvement in all C&P projects. Going the extra mile
                        through closely supervising projects from inception to
                        completion.
                      </p>
                      <p>
                        Faisal plays an integral role in running smooth
                        operations and asserting highest quality into the
                        execution, while simultaneously guaranteeing client
                        satisfaction, by meeting and exceeding their
                        expectations, Faisal Bouzo has been ranked 64 on the
                        2017 Construction Week Power 100 list most influential
                        individuals within the Middle East construction sector.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 md-4 sm-12 col-12 order-1 order-lg-2">
                    <div className="thumb position-relative">
                      <img
                        className="w-100"
                        src={Team[1].src}
                        alt="Service Images"
                      />
                    </div>
                  </div>
                </div> */}
                {/* 
                <div className="container sercice-details-content ptb--40 align-items-center">
                  <div
                    className="card col-lg-12"
                    style={{ maxHeight: "450px", opacity: 1 }}
                  >
                    <div className="row">
                      <div className="col-lg-3" style={{ padding: 0 }}>
                        <img
                          src={Team[1].src}
                          alt="Avatar"
                          style={{ maxHeight: "450px", opacity: 1 }}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="details mt_md--30 mt_sm--30 col-lg-12">
                            <h4 className="title">{Team[1].name}</h4>
                            <h5 className="title">{Team[1].designation}</h5>
                            <p>
                              Faisal Bouzo, a Saudi national and holder of the
                              Business Administration degree – with Emphasis on
                              International Management& Entrepreneurship – from
                              the American University of Beirut, is identified
                              through his comprehensive managerial skills and
                              his hands-on involvement in all C&P projects.
                              Going the extra mile through closely supervising
                              projects from inception to completion.
                            </p>
                          </div>
                          <div className="col-lg-12">
                            <a className="horizontal" href="/">
                              <span className="text">Know More &#8594;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Area */}
                {/* Start Single Area */}
                {/* <div className="container sercice-details-content ptb--40 align-items-center">
                  <div
                    className="card col-lg-12"
                    style={{ maxHeight: "450px", opacity: 1 }}
                  >
                    <div className="row">
                      <div className="col-lg-3" style={{ padding: 0 }}>
                        <img
                          src={Team[2].src}
                          alt="Avatar"
                          style={{ maxHeight: "450px", opacity: 1 }}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="details mt_md--30 mt_sm--30 col-lg-12">
                            <h4 className="title">{Team[2].name}</h4>
                            <h5 className="title">{Team[2].designation}</h5>
                            <p>
                              Tarek Ajlani, a Saudi National with a rich
                              background in creative ideas and techniques,
                              nurtured and sculpted through his education at
                              Virginia Tech (where he earned the Master’s Degree
                              in Architecture – with Emphasis on Building
                              Technology), started his professional journey at
                              SOM, Chicago, and then partnered with Faisal Bouzo
                              to establish C&P.
                            </p>
                          </div>
                          <div className="col-lg-12">
                            <a className="horizontal" href="/">
                              <span className="text">Know More &#8594;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Area */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
