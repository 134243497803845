import React, { Component } from "react";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Gallery.scss";
import { Trans } from "react-i18next";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      isOpen: false,
    };
  }
  render() {
    const { tab1, isOpen } = this.state;
    return (
      <div className="rn-portfolio-area">
        <div className="row">
          {this.props.data.map((value, index) => (
            <div className="col-md-6 col-12 px-4 px-md-4 px-lg-5" key={index}>
              {isOpen && (
                <Lightbox
                  mainSrc={this.props.data[tab1].bigImage}
                  nextSrc={
                    this.props.data[(tab1 + 1) % this.props.data.length]
                  }
                  prevSrc={
                    this.props.data[
                      (tab1 + this.props.data.length - 1) %
                        this.props.data.length
                    ]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      tab1:
                        (tab1 + this.props.data.length - 1) %
                        this.props.data.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      tab1: (tab1 + 1) % this.props.data.length,
                    })
                  }
                />
              )}
              <div className="item-portfolio-static mb-5">
                <div className="on-going" >
                  <p>
                    <Trans>
                      {value.category}
                    </Trans>
                  </p>
                  <img src={'../assets/images/' + value.category + '.png'} />
                </div>
                <div
                  onClick={() =>
                    this.setState({ isOpen: true, tab1: index })
                  }
                >
                  <div className="portfolio-static">
                    <div className="thumbnail-inner">
                      <div className="thumbnail">
                        <a href="#portfolio-details">
                          <img
                            src={value.bigImage}
                            alt="Portfolio Images"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <h4 className="py-4 mb-0">
                          <a class="brown-color" href="/">
                            <Trans>{value.title}</Trans>
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Gallery;
