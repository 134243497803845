import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        countNum: 10,
        suffix: "K",
        countTitle: "text13",
      },
      {
        countNum: 2,
        suffix: "",
        countTitle: "text14",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--2 col-lg-6 col-md-6 col-sm-6 col-12"
              key={index}
            >
              <h5 className="counter">
                {value.countTitle !== "text14" && <span>+</span>}
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0}
                  />
                </VisibilitySensor>
                <span>
                  <Trans>{value.suffix}</Trans>
                </span>
              </h5>
              <Fade up small>
                <p className="description">
                  <Trans>{value.countTitle}</Trans>
                </p>
              </Fade>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
