import React from "react";
import "./footer.scss";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

// import {
//   FaTwitter,
//   FaInstagram,
//   FaFacebookF,
//   FaLinkedinIn,
// } from "react-icons/fa";
// import { MapContainer } from "../Maps/Maps";

// const SocialShare = [
//   { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
//   { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
//   { Social: <FaInstagram />, link: "https://www.instagram.com/" },
//   { Social: <FaTwitter />, link: "https://twitter.com/" },
// ];
export default function Footer() {
  const { i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  };
  let lang = localStorage.getItem("i18nextLng");

  return (
    <React.Fragment>
      <footer className="app-footer">
        <div className="container">
          <div className="row d-lg-flex d-none">
            <div className="mb-5 col-lg-4 d-none d-md-block text-center">
              <img src="assets/images/footer-logo.png" alt="" />
              <p className="mt-4 mb-0">
                <Trans>text32</Trans>
              </p>
            </div>
            <div className="col-lg-8 d-flex justify-content-between">
              <div className="mb-5">
                <div className="footer-link">
                  <h4>
                    <Trans>text27</Trans>
                  </h4>
                  <ul className="ft-link">
                    <li>
                      <a href="/about">
                        <Trans>About Us</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="/services">
                        <Trans>Services</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="/investor-relations">
                        <Trans>InvestorRelations</Trans>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <span className="line">
                <span></span>
              </span>

              <div className="mb-5">
                <div className="footer-link">
                  <h4>
                    <Trans>text28</Trans>
                  </h4>
                  <ul className="ft-link">
                    <li>
                      <a href="/services">
                        <Trans>text29</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="/services">
                        <Trans>text30</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="/services">
                        <Trans>text07</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="/services">
                        <Trans>text31</Trans>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <span className="line">
                <span></span>
              </span>

              <div className="mb-5">
                <div className="footer-link">
                  <h4>
                    <Trans>Contact Us</Trans>
                  </h4>
                  <ul className="ft-link">
                    <li>
                      <a href="tel:+966 11 2033116" className="phone">+966 11 2033116</a>
                    </li>

                    <li>
                      <a href="mailto:info@the1stavenue.com">
                        info@the1stavenue.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>
          </div>

          <div className="row d-lg-none">
            <div className="mb-5 col-sm-6 col-12 col-lg-4 d-none d-md-block ">
              <img src="assets/images/footer-logo.png" alt="" />
              <p className="mt-4 mb-0">
                <Trans>text32</Trans>
              </p>
            </div>

            <div className="mb-5 col-lg-2 col-sm-6 md-6">
              <div className="footer-link">
                <h4>
                  <Trans>text27</Trans>
                </h4>
                <ul className="ft-link">
                  <li>
                    <a href="/about">
                      <Trans>About Us</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <Trans>Services</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="/investor-relations">
                      <Trans>InvestorRelations</Trans>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mb-5 col-lg-3 col-sm-6 md-6">
              <div className="footer-link">
                <h4>
                  <Trans>text28</Trans>
                </h4>
                <ul className="ft-link">
                  <li>
                    <a href="/services">
                      <Trans>text29</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <Trans>text30</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <Trans>text07</Trans>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <Trans>text31</Trans>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mb-5 col-lg-3 col-sm-6 md-6 mt_mobile--30">
              <div className="footer-link">
                <h4>
                  <Trans>Contact Us</Trans>
                </h4>
                <ul className="ft-link">
                  <li>
                    <a href="tel:+966 11 2033116">+966 11 2033116</a>
                  </li>

                  <li>
                    <a href="mailto:info@the1stavenue.com">
                      info@the1stavenue.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mb-5 col-sm-6 d-md-none">
              <img
                className="mb-4"
                src="assets/images/footer-logo.png"
                alt=""
              />
              <p>
                <Trans>text32</Trans>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
