import React, { Component, Fragment } from "react";
import "./Home.css";
import Sectors from "../components/Common/Sectors/Sectors";
import About from "../components/Common/About";
import Layout from "../components/Utils/Layout";
import Experience from "../components/Portfolio/Experience";
import Breadcrumb from "../components/Common/Breadcrumb";

class OurProjects extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

 
    images = {
        url: process.env.PUBLIC_URL + "/assets/images/our-projects/our-projects_breadcrumb.png",
    };

  render() {
    
    return (
      <Fragment>
        <Layout>
          <Breadcrumb title={"Projects"}  image={this.images} />
          <About />
          <Sectors />
          <Experience />
        </Layout>
      </Fragment>
    );
  }
}
export default OurProjects;
