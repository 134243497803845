import React, { Component, Fragment } from "react";
import "./Home.css";
import Sectors from "../components/Common/Sectors/Sectors";
import About from "../components/Common/About";
// import Teams from "../components/Common/Team/Team";
import Helmet from "../components/Utils/Helmet";
// import Brands from "../components/Common/Clients/Brands";
// import ColumnsTwo from "../components/Common/Columns/ColumnsTwo";
import Layout from "../components/Utils/Layout";
// import MapContainer from "../components/Common/Maps/HomeMap";
import ControlledCarousel from "../components/Common/Carousel/index.jsx";
// import ServiceTwo from "../components/Common/Service/ServiceTwo";
import Experience from "../components/Portfolio/Experience";
import Title from "../components/Common/Title";
// import { Parallax } from "react-parallax";
// import parallaxImage from "../assets/Images/assignment2.jpg";
import { FiUsers, FiHome } from "react-icons/fi";
import {TiPointOfInterest} from "react-icons/ti";
import {RiSlideshowLine} from "react-icons/ri";

import Fade from "react-reveal/Fade";
class Home extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const ServiceSectionNew = [
      {
        icon: <FiHome />,
        title: "real estate development",
        description:
          "Land Acquisition, Use Analysis, Concept Design, Development and Property Management",
      },
      {
        icon: <FiUsers />,
        title: "real estate development Management & Advisory",
        description:
          "Portfolio Management Advisory, Assets Consultancy, DD services, Corporate Set-Up, Organization Restructuring, Financing Advisor",
      },
      {
        icon: <RiSlideshowLine />,
        title: "Real Estate Marketing",
        description:
          "Land Acquisition, Use Analysis, Concept Design, Development and Property Management",
      },
      {
        icon: <TiPointOfInterest />,
        title: "High and Best use Studies",
        description:
          "Portfolio Management Advisory, Assets Consultancy, DD services, Corporate Set-Up, Organization Restructuring, Financing Advisor",
      },
    ];
    return (
      <Fragment>
        <Layout hideFooter={true}>
          <Helmet pageTitle="First Avenue" />
          <ControlledCarousel />

          {/* <About />
          <Sectors />
          <Experience /> */}
         
        </Layout>

      </Fragment>
    );
  }
}
export default Home;
