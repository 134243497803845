import React, { Component } from "react";
import Fade from "react-reveal/Fade";
// import TabTwo from "../TabTwo";
// import CounterTwo from "../Counter/CounterTwo";
import Title from "../Title";
import { FaBullseye, FaMountain } from "react-icons/fa";
// import { FiUsers } from "react-icons/fi";
import { FiStar } from "react-icons/fi";
import { Trans } from "react-i18next";

import "./AboutUs.scss";

// import CompleteLogo from "/assets/Images/logo/CompleteLogo.png";
class About extends Component {
  render() {
    // let title = "First Avenue",
    let description = ["About01", "About02", "About03"];
    // var development = [
    //   "Land acquisition",
    //   " Concept Design",
    //   "Licensing",
    //   "Development Management",
    // ];
    const ServiceListOne = [
      {
        // icon: <FaMountain />,
        icon: "assets/icons/lamp.svg",
        title: "OurVision",
        description: "OurVisionPar",
        class: "col-12 col-lg-6",
        paragraphs:["OurVisionPar"],

      },
      {
        // icon: <FaBullseye />,
        icon: "assets/icons/mission.svg",
        title: "OurMission",
        description: "OurMissionPar",
        paragraphs:["OurMissionPar"],
        class: "col-12 col-lg-6",
      },
      {
        // icon: <FaBullseye />,
        icon: "assets/icons/values.svg",
        title: "OurValues",
        description: "OurValuesPar",
        paragraphs: [
          "p1",
          "p2",
          "p3",
          "p4",
          "p5",
        ],
        class: "col-12",
      },
      // {
      //   icon: <FiUsers />,
      //   title: "Our Values",
      //   description:
      //     "I throw myself down among the tall grass by the stream as I lie close to the earth.",
      // },
    ];

    const values = [
      {
        title: "Passion",
        description: "The only way to do great work is to love what we do",
      },
      {
        title: "Innovation",
        description:
          "We constantly strive to provide innovative solutions that exceed our clients expectations through the real estate products and services that we offer",
      },
      {
        title: "Sincerity",
        description:
          "Our clients are the focus of our case. We are keen to communicate with them wherever they are and we seek to satisfy them through constructive discussions that will exceed their expectations.",
      },
      {
        title: "Responsibility",
        description:
          "We feel accountable in everything we do, and we accept ownership for our decisions we make in all our dealings.",
      },
      {
        title: "Flexibility",
        description:
          "We do our work with the highest level of flexibility and efficacy, and we are always willing to meet new challenges.",
      },
    ];

    return (
      <React.Fragment>
        <div className="about-wrapper pt--60 pb--30">
          <div className="container">
            <div className="row row--35 align-items-center">
              {/* <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/logo/CompleteLogo.png"
                    alt="About Images"
                  />
                </div>
              </div> */}

              <div className="col-lg-12 col-md-12">
                <div className=" section-title service-style about-inner inner text-center">
                  <div className="section-title">
                    {/* <Title title={title} /> */}
                    <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                      <Title title={"OurStory"} />
                      {/* <Fade up small> */}{" "}
                      {description.map((item, index) => (
                        <Fade up small key={index}>
                          <p className="description">
                            <Trans>{item}</Trans>
                          </p>
                        </Fade>
                      ))}
                      {/* </Fade> */}
                    </div>

                    {/* <p className="description">{description}</p>
                    <p className="description">{description1}</p>
                    <p className="description">{description2}</p>
                    <p className="description">{description4}</p> */}

                    {/* SERVICE details\ */}
                    {/* <div className="service-details-inner">
                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-lg-6 col-12">
                          <div className="details mt_md--30 mt_sm--30">
                            <h4 className="title">Proceess of metel</h4>
                            <ul className="liststyle">
                              <li>Yet this above sewed flirted opened ouch</li>
                              <li>Goldfinch realistic sporadic ingenuous</li>
                              <li>
                                Abominable this abidin far successfully then
                                like piquan
                              </li>
                              <li>Risus commodo viverra</li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* Service Deatils End */}
                  </div>
                  {/* <div className="row mt--30 mt_sm--10"> */}
                  {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Mission</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Vision</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  {/* </div> */}
                  {/* <CounterTwo /> */}
                  {/* <div className="row mt--30 ">
                    <TabTwo
                      tabStyle="tab-style--1"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div className={val.class} key={i}>
                  <a className="text-center" href="/service-details">
                    <div className="service service__style--2 d-md-flex align-items-center ">
                      {/* <div className="icon">{val.icon}</div> */}
                      <div className="mx-0 mr-md-4 ml-md-4 icon-cont">
                        <img src={val.icon} />
                        <h3 className="title mb-3 mb-md-0">
                          <Trans>{val.title}</Trans>
                        </h3>
                      </div>
                      <div className="content">
                        {
                          val.paragraphs.map( (p, d) => {
                            return <p  key={d}>
                              <Trans>{p}</Trans>
                            </p>
                          } )
                        }
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            {/* <div className="mt--30">
                      <Fade up small>
                        <h4>Our Values:</h4>
                        <ul className="list-style--1">
                          {values.map((item, index) => {
                            return (
                              <li key={index}>
                                <FiStar /> 
                                <b>{item.title}</b> :
                                  <p>{item.description}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </Fade>
                    </div> */}
          </div>
        </div>

        {/* <div className="mt--30">
          <Fade up small>
            <h4>Our Offering Includes: </h4>
            <ul className="list-style--1">
              {values.map((name, index) => {
                return <li key={index}>{name.title}</li>;
              })}
            </ul>
          </Fade>
        </div> */}
      </React.Fragment>
    );
  }
}
export default About;
