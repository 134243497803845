import React from "react";
import Fade from "react-reveal/Fade";
import Gallery from "../Gallery";
import Title from "../Common/Title";

export default function Experience() {
  const TabOne = [
    {
      image: "01",
      bigImage: "/assets/images/riyadh/al-diriyah-avenue.jpg",
      category: "Ongoing",
      title: "text19",
    },
    {
      image: "02",
      bigImage: "/assets/images/riyadh/experience/10.jpg",
      category: "Ongoing",
      title: "text20",
    },
    {
      image: "03",
      bigImage: "/assets/images/slider/Home_2.jpg",
      category: "Ongoing",
      title: "text21",
    },
    {
      image: "04",
      bigImage: "/assets/images/home-experience/al-qirawan-gate.jpeg",
      category: "Ongoing",
      title: "text22",
    },
    {
      image: "05",
      bigImage: "/assets/images/riyadh/4.jpg",
      category: "Completed",
      title: "text23",
    },
    {
      image: "06",
      bigImage: "/assets/images/riyadh/experience/11.jpg",
      category: "Completed",
      title: "text24",
    },
    // {
    //   image: "07",
    //   bigImage: "/assets/images/riyadh/9.jpg",
    //   category: "Completed",
    //   title: "Al Yasmin Avenue",
    // },
    // {
    //   image: "08",
    //   bigImage: "/assets/images/riyadh/10.jpg",
    //   category: "Completed",
    //   title: "Jawharat Al-Tawun",
    // },
  ];
  return (
    <div id="portfolio" className="fix">
      <div className="portfolio-area ptb--60">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <Fade up small>
                    <Title title="Projects in Riyadh" />
                  </Fade>
                </div>
              </div>
            </div> */}
            <Fade up small>
              <Gallery data={TabOne} />
            </Fade>
           
          </div>
        </div>
      </div>
    </div>
  );
}
