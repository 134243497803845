import React from "react";

//Icons
import { GiCoins } from "react-icons/gi";
import { FaCalendarAlt, FaKey } from "react-icons/fa";
import { BsPuzzleFill } from "react-icons/bs";
import { AiFillDollarCircle, AiOutlineClockCircle } from "react-icons/ai";

export const ProjectList = [
  {
    id: "1",
    name: "Al Ghadeer Avenue",
    mapData: {
      projectName: "Al Ghadeer Avenue",
      lat: 24.748094,
      lng: 46.636166,
    },
    content: `Modern Residential Apartments Consist of 4 building with common basement floor. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "3000 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "20 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Rent",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "26 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/4.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan5.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan6.jpg",
      },
      {
        alt: "7",
        image: "7",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan7.jpg",
      },
      {
        alt: "8",
        image: "8",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan8.jpg",
      },
      {
        alt: "9",
        image: "9",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Ghadeer_Avenue/floorplan/Floorplan9.jpg",
      },
    ],
  },
  {
    id: "2",
    name: "Al Jawhara",
    mapData: {
      projectName: "Al Jawhara",
      lat: 24.774085,
      lng: 46.705927,
    },
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "3000 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "20 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Rent",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "26 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/1.jpg",
      },
      {
        alt: "2",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/2.jpg",
      },
      {
        alt: "3",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/3.jpg",
      },
      {
        alt: "4",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/4.jpg",
      },
      {
        alt: "5",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/5.jpg",
      },
      {
        alt: "6",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/6.jpg",
      },
      {
        alt: "7",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/7.jpg",
      },
      {
        alt: "8",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Jawhara/8.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Jawhara/floorplans/floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Jawhara/floorplans/floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Jawhara/floorplans/floorplan3.jpg",
      },
    ],
  },
  {
    id: "3",
    name: "Al Majdiah 21",
    mapData: {
      projectName: "Al Majdiah 21",
      lat: 24.733304,
      lng: 46.644391,
    },
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2018",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "1960 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "14.8 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "11 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "19.374 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/4.jpg",
      },
      {
        alt: "5",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/5.jpg",
      },
      {
        alt: "6",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/6.jpg",
      },
      {
        alt: "7",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/7.jpg",
      },
      {
        alt: "8",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/8.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Majdiyah_21/floorplans/Floorplan4.jpg",
      },
    ],
  },
  {
    id: "4",
    name: "Al Nada",
    mapData: {
      projectName: "Al Nada",
      lat: 24.804852,
      lng: 46.681951,
    },
    content: `Modern Residential Apartments Consist of 7 building with basement of each building. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "4,473 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "18 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-15 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "20.160 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/1.jpg",
      },
      {
        alt: "2",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/2.jpg",
      },
      {
        alt: "3",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/3.jpg",
      },
      {
        alt: "4",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/4.jpg",
      },
      {
        alt: "5",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/5.jpg",
      },
      {
        alt: "6",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/6.jpg",
      },
      {
        alt: "7",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/7.jpg",
      },
      {
        alt: "8",
        url: process.env.PUBLIC_URL + "/assets/images/project/Al_Nada/8.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nada/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nada/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nada/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nada/floorplans/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nada/floorplans/Floorplan5.jpg",
      },
    ],
  },
  {
    id: "5",
    name: "Al Nakheel Avenue",
    mapData: {
      projectName: "Al Nakheel Avenue",
      lat: 24.748094,
      lng: 46.636166,
    },
    content: `Modern Residential Apartment consist of 28 Apartments in the west northern Riyadh which is one of the most prime districts in the city. In this project we tried to make it affordable area for the new marriage partner and the small families and also workers who works on the attraction centers and towers around the district. Our location is only 0.4 KM away from KAFD ( King Abdullah Financial District ) and ITCC (Information Technology & Communication Complex) . `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "1,994.2 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "14.2 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-18 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "18 mn",
      },
    ],

    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/4.jpg",
      },
      {
        alt: "5",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/5.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan5.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan6.jpg",
      },
      {
        alt: "7",
        image: "7",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan7.jpg",
      },
      {
        alt: "8",
        image: "8",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan8.jpg",
      },
      {
        alt: "9",
        image: "9",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan9.jpg",
      },
      {
        alt: "10",
        image: "10",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan10.jpg",
      },
    ],
  },
  {
    id: "6",
    name: "Al Nakheel Offices",
    mapData: {
      projectName: "Al Nakheel Offices",
      lat: 24.748578,
      lng: 46.635514,
    },
    content: `Boutique offices next to the ITCC, the Project consist of 5 boutique offices with size of 110 SQM per office.  Two offices per floor and one office in the roof. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "4,473 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "18 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-15 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "20.160 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/1.JPG",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/4.jpg",
      },
      {
        alt: "5",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/5.jpg",
      },
      {
        alt: "6",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/6.jpg",
      },
      {
        alt: "7",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/7.jpg",
      },
      {
        alt: "8",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/8.jpg",
      },
      {
        alt: "9",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/9.jpg",
      },
      {
        alt: "10",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/10.JPG",
      },
      {
        alt: "11",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/11.JPG",
      },
      {
        alt: "12",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/12.JPG",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/floorplans/Floorplan3.jpg",
      },
    ],
  },
  {
    id: "7",
    name: "Al Sahafa",
    mapData: {
      projectName: "Al Sahafa",
      lat: 24.829151,
      lng: 46.629508,
    },
    content: `Gas station on a 3,000 SQM Land that consist 2 gas island and mix used shops (coffee, ATM, minimarket, car service and a car wash. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "3000 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "13 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "10 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Rent",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "17.3 mn",
      },
    ],
    images: [
      {
        alt: "12",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Offices/12.JPG",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Sahafa/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Sahafa/floorplans/Floorplan2.jpg",
      },
    ],
  },
  {
    id: "8",
    name: "Al Yasmin Residence",
    mapData: {
      projectName: "Al Yasmin Residence",
      lat: 24.832515,
      lng: 46.646793,
    },
    content: `Modern Residential Apartments Consist of 3 building with common basement floor. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "2400 sqm",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "15.5 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-18 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "19.2 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/1.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan5.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan6.jpg",
      },
      {
        alt: "7",
        image: "7",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan7.jpg",
      },
      {
        alt: "8",
        image: "8",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan8.jpg",
      },
      {
        alt: "9",
        image: "9",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan9.jpg",
      },
      {
        alt: "10",
        image: "10",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/floorplans/Floorplan10.jpg",
      },
    ],
  },
  {
    id: "10",
    name: "Al Qirawan Gate",
    mapData: {
      projectName: "Al Qirwan Gate",
      lat: 24.830722,
      lng: 46.599278,
    },
    content: `Commercial Plaza on the northern Riyadh in Al Qirawan dist. The land located on the heart of Al Qirawan Dist and also the plaza on the 3 main intersection of Al Qirawan dist. In & out. Specially the land can be called the main axes of King Fahad Rd to Prince Turki Alawal Rd. `,
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "4,473 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "18 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-15 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "20.160 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Yasmin_Residence/1.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/AlQirawan_Gate/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/AlQirawan_Gate/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/AlQirawan_Gate/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/AlQirawan_Gate/floorplans/Floorplan4.jpg",
      },
    ],
  },
  {
    id: "11",
    name: "Basmat Al Yasamin",
    mapData: {
      projectName: "Basmat Al Yasamin",
      lat: 24.748094,
      lng: 46.636166,
    },
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2017",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "600 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "3.2 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-15 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "3.65 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/4.jpg",
      },
      {
        alt: "5",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/5.jpg",
      },
      {
        alt: "6",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/6.jpg",
      },
      {
        alt: "7",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/7.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/450/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/450/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/450/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/450/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/600/Floorplan1.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/600/Floorplan2.jpg",
      },
      {
        alt: "7",
        image: "7",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/600/Floorplan3.jpg",
      },
      {
        alt: "8",
        image: "8",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/600/Floorplan4.jpg",
      },
      {
        alt: "9",
        image: "9",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/750/Floorplan1.jpg",
      },
      {
        alt: "10",
        image: "10",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/750/Floorplan2.jpg",
      },
      {
        alt: "11",
        image: "11",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/750/Floorplan3.jpg",
      },
      {
        alt: "12",
        image: "12",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Basmat_Al_Yasamin/floorplans/750/Floorplan4.jpg",
      },
    ],
  },
  {
    id: "12",
    name: "Danat Al Yasamin",
    mapData: {
      projectName: "Danat Al Yasamin",
      lat: 24.815934,
      lng: 46.66089,
    },
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2016",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "1,014 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "7.5 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "8.7 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/3.jpg",
      },
      {
        alt: "4",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/4.jpg",
      },
      {
        alt: "5",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/5.jpg",
      },
      {
        alt: "6",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/6.jpg",
      },
      {
        alt: "7",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/7.jpg",
      },
      {
        alt: "8",
        url:
          process.env.PUBLIC_URL +
          "/assets/images/project/Danat_Al_Yasamin/8.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan5.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Al_Nakheel_Avenue/floorplans/Floorplan6.jpg",
      },
    ],
  },
  {
    id: "13",
    name: "Hittin Pearl",
    mapData: {
      projectName: "Al Nakheel Avenue",
      lat: 24.773841,
      lng: 46.608677,
    },
    stats: [
      {
        icon: <FaCalendarAlt className="customIcon" />,
        head: "Completed",
        content: "2019",
      },
      {
        icon: <BsPuzzleFill className="customIcon" />,
        head: "Area",
        content: "4,473 sqm ",
      },
      {
        icon: <AiFillDollarCircle className="customIcon" />,
        head: "Cost",
        content: "18 mn",
      },
      {
        icon: <AiOutlineClockCircle className="customIcon" />,
        head: "Timeline",
        content: "12-15 months",
      },
      {
        icon: <FaKey className="customIcon" />,
        head: "Exit Strategy",
        content: "Sale",
      },
      {
        icon: <GiCoins className="customIcon" />,
        head: "Exit Value",
        content: "20.160 mn",
      },
    ],
    images: [
      {
        alt: "1",
        url:
          process.env.PUBLIC_URL + "/assets/images/project/Hittin_Pearl/1.jpg",
      },
      {
        alt: "2",
        url:
          process.env.PUBLIC_URL + "/assets/images/project/Hittin_Pearl/2.jpg",
      },
      {
        alt: "3",
        url:
          process.env.PUBLIC_URL + "/assets/images/project/Hittin_Pearl/3.jpg",
      },
    ],
    floorplans: [
      {
        alt: "1",
        image: "1",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan1.jpg",
      },
      {
        alt: "2",
        image: "2",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan2.jpg",
      },
      {
        alt: "3",
        image: "3",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan3.jpg",
      },
      {
        alt: "4",
        image: "4",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan4.jpg",
      },
      {
        alt: "5",
        image: "5",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan5.jpg",
      },
      {
        alt: "6",
        image: "6",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan6.jpg",
      },
      {
        alt: "7",
        image: "7",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan7.jpg",
      },
      {
        alt: "8",
        image: "8",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan8.jpg",
      },
      {
        alt: "9",
        image: "9",
        bigImage:
          process.env.PUBLIC_URL +
          "/assets/images/project/Hittin_Pearl/floorplans/Floorplan9.jpg",
      },
    ],
  },
];
