import React from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { Trans } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';
// import en from "../../../assets/Images/en.png";
// import ar from "../../../assets/Images/ar.png";
// import { ServiceList } from "../../../data/HeaderData";
// import { data } from "../../../data/Projects.js";
function HeaderFive(props) {
  // const { i18n } = useTranslation();
  // const [language, setLanguage] = useState("en");
  // useEffect(() => {
  //   var lang = localStorage.getItem("defaultLanguage") || 0;
  //   if (lang === "en") {
  //     console.log("en");
  //     i18n.changeLanguage(lang);
  //     setLanguage(lang);
  //   } else if (lang === "ar") {
  //     console.log("ar");
  //     i18n.changeLanguage(lang);
  //     setLanguage(lang);
  //   }
  // }, [i18n]);

  function menuTrigger() {
    console.log("triggered");
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  function CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  // const handleClick = (lang) => {
  //   console.log(lang);
  //   localStorage.setItem("defaultLanguage", lang);
  //   i18n.changeLanguage(lang);
  //   setLanguage(lang);
  // };
  // render() {
  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  let logoUrl;
  let color = "";
  logoUrl = <img src="/assets/images/logo/app-logo.png" alt="First Avenue" />;



  const { i18n } = useTranslation();

  const handleLanguageChange = () => {
    let lng = (lang == 'ar') ? 'en' : 'ar';
    
    localStorage.setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  };
  let lang = localStorage.getItem('i18nextLng')

  return (
    <header
      className={`header-area formobile-menu header--fixed logoresize  ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="container">
          <div className="d-flex justify-content-between flex-row-reverse">
            <div>
              {/* <nav className="mainmenunav d-lg-block">
                <ul className="mainmenu">
                  <li>
                    <Link to="/about">
                      <Trans>About Us</Trans>
                    </Link>
                  </li>
                  <li className="has-droupdown">
                    <Link to="/#">
                      <Trans>Projects</Trans>
                    </Link>
                    <ul className="submenu">
                      <li key="riyadh">
                        <Link to="/experience-riyadh">
                          <Trans>Riyadh</Trans>
                        </Link>
                      </li>
                      <li key="jeddah">
                        <Link to="/experience-jeddah">
                          <Trans>Jeddah</Trans>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="">
                    <Link to="/services">
                      <Trans>Services</Trans>
                    </Link>
                  </li>
                  <li>
                    <Link to="/investor-relations">
                      <Trans>InvestorRelations</Trans>
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact">
                      <Trans>Contact Us</Trans>
                    </Link>
                  </li>
                  <li>
                    {lang == 'ar' && <a className="btn lang-btn" onClick={() => handleLanguageChange('en')}>English</a>}
                    {lang == 'en' && <a className="btn lang-btn" onClick={() => handleLanguageChange('ar')}>عربى</a>}
                  </li>
                </ul>
              </nav> */}

              <Dropdown className="d-block  pl--20 pt-3">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="d-block  pl--20 dropdown-nav "
                >
                  {/* <FiMenu /> */}
                  <img src="assets/icons/menu-item.svg" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/about">
                    <Trans>About Us</Trans>
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item as={Link} to="/our-projects">
                    <Trans>Projects</Trans>
                  </Dropdown.Item>

                  {/* <Dropdown.Item as={Link} to="/experience-riyadh" className="px-5" >
                    <Trans>Riyadh</Trans>
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to="/experience-jeddah"  className="px-5" >
                    <Trans>Jeddah</Trans>
                  </Dropdown.Item> */}
                  

                  <Dropdown.Divider />

                  <Dropdown.Item as={Link} to="/services">
                    <Trans>Services</Trans>
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item as={Link} to="/investor-relations">
                    <Trans>InvestorRelations</Trans>
                  </Dropdown.Item>
                  <Dropdown.Divider />


                  <Dropdown.Item as={Link} to="/contact">
                    <Trans>Contact Us</Trans>
                  </Dropdown.Item>
 
                  <Dropdown.Divider />

                    {/* Translation Button  */}
                  <Dropdown.Item  onClick={() => handleLanguageChange()}  >
                    <Trans>Lang</Trans>
                  </Dropdown.Item>



 

                </Dropdown.Menu>
              </Dropdown>

              {/* <div className="humberger-menu d-block d-lg-none pl--20">
                  <span
                    onClick={() => menuTrigger()}
                    className="menutrigger text-white"
                  >
                    <FiMenu />
                  </span>
                </div>
                <div className="close-menu d-block d-lg-none">
                  <span
                    onClick={() => CLoseMenuTrigger()}
                    className="closeTrigger"
                  >
                    <FiX />
                  </span>
                </div> */}
            </div>

            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
  // }
}
export default HeaderFive;
