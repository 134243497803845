import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.scss";
import { Trans } from "react-i18next";

// import KnowMoreButton from "../KnowMoreButton";
// import { ServiceList } from "../../../data/ServiceList";

// import brokerage from "../../../assets/Images/slider/brokerage.jpg";
// import consulting from "../../../assets/Images/slider/consulting.jpg";
// import development from "../../../assets/Images/slider/slider2.jpg";
// import valuation from "../../../assets/Images/slider/valuation.jpg";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const imageData = [
    "/assets/images/slider/Home_1.jpg",
    "/assets/images/slider/Home_1.jpg",
  ];

  const sliderData = [
    {
      title: "ValueDevelopment",
    },
    {
      title: "ValueDevelopment",
    },
  ];
  // const sliderData = [
  //   {
  //     title: "text41",
  //     description:"text42",
  //   },
  //   {
  //     title: "text41",
  //     description:"text42",
  //   }
  // ];
  return (
    <div className="carousal-cont">
      <h2>
        <Trans>ValueDevelopment</Trans>
      </h2>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {sliderData.map((value, index) => {
          const { title, description } = value;
          return (
            <Carousel.Item key={index}>
              <div className="" style={{ height: "100vh" }}>
                {/* <div className="app-overlay dark"></div> */}
                <img
                  className="d-inline-block"
                  src={imageData[index]}
                  alt="First slide"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    overflow: "hodden",
                    height: "100vh",
                  }}
                />
                <VisibilitySensor delayedCall>
                  <div className="caption in-left">
                   
                    {/* <p>
                      <Trans>{description}</Trans>
                    </p> */}
                  </div>
                </VisibilitySensor>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default ControlledCarousel;
