import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import Layout from "../components/Utils/Layout";
// import ServicesTab from "../components/Common/Service/ServicesTab";
// import { FiCheck } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import Title from "../components/Common/Title";
import Experience from "../components/Portfolio/ExperienceJeddah";
import CounterOne from "../components/Common/CounterJeddah";
// import development from "/assets/images/servicepage/development.jpg";

export default function JeddahPage() {

  const images = {
    url: process.env.PUBLIC_URL + "/assets/images/jeddah/jeddah_breadcrumb.jpg",
  };
  return (
    <Layout>
      <Breadcrumb title={"Jeddah"} image={images} />
      {/* <div className="section-title text-center mt--30 mb--30">
        <Fade up small>
          <Title title={"Jeddah"} />
        </Fade>
      </div> */}
      <div className="rn-service-details ptb--60 bg_color--1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12 ptb--60">
              <CounterOne />
            </div>
          </div>
        </div>
        <Experience />
      </div>
    </Layout>
  );
}
