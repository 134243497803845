import React, { Component } from "react";
import PageHelmet from "../../Utils/Helmet";
import { FiHeadphones, FiMail } from "react-icons/fi";
// import ContactTwo from "./ContactTwo";
// import GoogleMapReact from "google-map-react";
import Layout from '../../Utils/Layout'
// const AnyReactComponent = ({ text }) => <div>{text}</div>;
import Breadcrumb from "../Breadcrumb";
import "./Contact.scss";
import { Trans } from "react-i18next";

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 28.7041,
      lng: 77.1025,
    },
    zoom: 11,
  };

  image = {
    url: process.env.PUBLIC_URL + "/assets/images/contact-us/breadcrumb.png",
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
        <Breadcrumb title={"Contact Us"} image={this.image} />

        <div className="pt--60 pb--60">
          <h3 className="text-center m-0 contact-header">
          <Trans>
            {'ContactTitle'}
          </Trans>
          </h3>
          <div className="container pt--60">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10 row">
                <div className="col-md-6 mb-3">
                  <div className="item py-4 px-4 py-lg-5 px-lg-5 text-center d-md-flex">
                    <img className="mr-md-3 mr-lg-4 ml-md-3 ml-lg-4 mb-3 mb-md-0" src="assets/icons/headphone.svg" alt="" />
                    <div  className="item-info">
                      <h4>
                      <Trans>{'PhoneNumber'}</Trans>
                      </h4>
                      <a href="tel:+966 11 2033116" class="phone">
                      +966 11 2033116
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                <div className="item py-4 px-4 py-lg-5 px-lg-5 text-center d-md-flex">
                    <img className="mr-md-3 mr-lg-4 ml-md-3 ml-lg-4 mb-3 mb-md-0" src="assets/icons/email.svg" alt="" />
                    <div  className="item-info">
                      <h4>
                        <Trans>{'EmailAddress'}</Trans>
                      </h4>
                      <a href="mailto:info@the1stvenue.com">
                      info@the1stvenue.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Contact Us</h2>
                  <p>
                  We will be happy to answer all your questions and inquiries. You can contact us at any time by phone or email using the following contact information.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-contact-top-area ptb--120 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Phone Number</h4>
                    <p>
                      <a href="tel:+966 11 2033116">+966 11 2033116</a>
                    </p>
                
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    <p>
                      <a href="mailto:info@the1stavenue.com">
                      info@the1stavenue.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        {/* <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div> */}
        </Layout>
      </React.Fragment>
    );
  }
}
export default Contact;
