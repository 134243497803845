import React from "react";
import Fade from "react-reveal/Fade";
import { Col } from "react-bootstrap";
import SectorCard from "./SectorCard";
import Title from "../Title";
import { SectorSection } from "../../../data/SectorData";
import { Trans } from "react-i18next";

export default function Sectors() {
  return (
    <div className="rn-about-area ptb--60">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                <Fade up small>
                  <Title title={"Sectors"} />
                </Fade>
                <Fade up small>
                  <p className="mb-5">
                  <Trans>text09</Trans>
                  </p>
                </Fade>
              </div>
            </div>
          </div>

          <div className="row">
            {/* style={{background:'url(../../assets/images/Offices.png)'}} */}
            <div className="col-md-4 mb-3" >
              <div className="blend-item">
                <img src="../../assets/images/Offices.png" />
                <h3>
                  <Trans>Offices</Trans>
                </h3>
              </div>
            </div>
            <div className="col-md-4 mb-3" >
              <div className="blend-item">
                <img src="../../assets/images/Residential.png" />
                <h3>
                <Trans>Residential</Trans>
                </h3>
              </div>
            </div>
            <div className="col-md-4 mb-3" >
              <div className="blend-item">
                <img src="../../assets/images/Retail.png" />
                <h3>
                <Trans>Retail</Trans>
                </h3>
              </div>
            </div>
            {/* {SectorSection.map((value, index) => {
              return (
                <Col
                  style={{ height: "300px", padding: "5px" }}
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                  key={index}
                >
                  <SectorCard key={index} data={value} />
                </Col>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
}
