// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// // not like to use this?
// // have a look at the Quick start guide 
// // for passing in lng and translations on init
// const Languages = ['en','ar']
// i18n
//   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
//   // learn more: https://github.com/i18next/i18next-http-backend
//   .use(Backend)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     fallbackLng: 'en',
//     debug: true,
//     whitelist: Languages,

//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     }
//   });


import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend'; // Example backend for loading translations
import Backend from 'i18next-xhr-backend';
i18next
  .use(Backend) // Load translations from a backend
  .use(initReactI18next) // Initialize i18next for React
  .init({
    resources: {
      en: { translation: require('./assets/locales/en/translation.json') },
      ar: { translation: require('./assets/locales/ar/translation.json') },
    },
    lng: localStorage.getItem('i18nextLng') || 'en', // Default language
    fallbackLng:localStorage.getItem('i18nextLng') || 'en', // Fallback language
  });


export default i18next;