/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import HeaderFive from "../Common/Header";
import Footer from "../Common/Footer/Footer";

const Layout = ({ children, hideFooter }, props) => {
  // const { hideFooter } = props;
  console.log('hideFooter', hideFooter);
  console.log('children', children);
  console.log('children', children);
  return (
    <div style={{ paddingTop: "0" }}>
      <HeaderFive />

      <main>{children}</main>

      {!hideFooter && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
