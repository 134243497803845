import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import Layout from "../components/Utils/Layout";
// import ServicesTab from "../components/Common/Service/ServicesTab";
import { FiCheck } from "react-icons/fi";
import Fade from "react-reveal/Fade";
// import development from "/assets/images/servicepage/development.jpg";
import { Trans } from "react-i18next";
import "./ServicePage.scss";
export default function ServicePage() {
  // var development = [
  //   "Land acquisition",
  //   " Concept Design",
  //   "Licensing",
  //   "Development Management",
  // ];
 
  // var studies = [
  //   "Highest & Best Use Analysis",
  //   "Feasibility Studies",
  //   "Market Value Estimation",
  // ];

  // var marketing = [
  //   "Market Studies",
  //   "Sales Strategy",
  //   "Marketing Strategy",
  //   "Road Shows",
  //   "End To End Customer Support",
  // ];
  // var management = [
  //   "Screening potential tenants",
  //   "Drafting, signing, and renewing leases on behalf of property owners",
  //   "Collecting rent",
  //   "Maintaining properties including landscaping and snow removal",
  //   "Arranging for necessary repairs to properties",
  //   "Setting up and adhering to budgets for property maintenance",
  // ];

  // const images = [
  //   {
  //     url:
  //       process.env.PUBLIC_URL + "/assets/images/servicepage/1.jpeg",
  //   },
  //   {
  //     url: process.env.PUBLIC_URL + "/assets/images/servicepage/2.jpeg",
  //   },
  //   {
  //     url:
  //       process.env.PUBLIC_URL + "/assets/images/servicepage/3.jpeg",
  //   },
  //   {
  //     url: process.env.PUBLIC_URL + "/assets/images/servicepage/4.jpeg",
  //   },
  // ];

  const images = {
    url:
      process.env.PUBLIC_URL +
      "/assets/images/services/services_breadcrumb.png",
  };

  const development = {
    par: "text43",
    head: "text44",
    list: ["text45", "text46", "text47", "text48"],
  };

  const management = {
    par: "text49",
    head: "text50",
    list: ["text51", "text52", "text53", "text54", "text55", "text56"],
  };

  const marketingData = {
    par: "text57",
    head: "text58",
    list: ["text59", "text60", "text61", "text62", "text63"],
  };

  const studies = {
    par: "text64",
    head: "text65",
    list: ["text66", "text67", "text68"],
  };



  return (
    <Layout>
      <Breadcrumb title={"Services"} image={images} />

      {/* Start About Area */}
      <div className="about-area  bg_color--1">
        <div className="section-title service-style--3 text-center mb--60 pt--60 pb--60  mt--60 mb_sm--0s">
          <Fade up small>
            {" "}
            <p>
              <Trans>text04</Trans>
            </p>
          </Fade>
        </div>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              {/* <div className="col-md-1"></div> */}
              <div className="row">
                <Fade up small>
                  <div className="col-lg-6 item-cont">
                    <div className="card">
                      <div className="content">
                        <div className="front">
                          <img src="assets/icons/building.svg" />
                          <h3>
                            <Trans>text05</Trans>
                          </h3>
                        </div>
                        <div className="back">
                          <p>
                            <Trans>{development.par}</Trans>
                          </p>
                          <h3>
                            <span>
                              <Trans>{development.head}</Trans>
                            </span>
                          </h3>
                          <ul>
                            {development.list.map((txt, i) => (
                              <li key={i}>
                               <div>
                                  <span className="square"></span>
                                  <span><Trans>{txt}</Trans></span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade up small>
                  <div className="col-lg-6 item-cont">
                    <div className="card">
                      <div className="content">
                        <div className="front">
                          <img src="assets/icons/advisory.svg" />
                          <h3>
                            <Trans>text05</Trans>
                            <br></br>
                            <Trans>text06</Trans>
                          </h3>
                        </div>
                        <div className="back">
                          <p>
                            <Trans>{management.par}</Trans>
                          </p>
                          <h3>
                            <span>
                              <Trans>{management.head}</Trans>
                            </span>
                          </h3>
                          <ul>
                            {management.list.map((txt, i) => (
                              <li key={i}>
                                <div>
                                    <span className="square"></span>
                                    <span><Trans>{txt}</Trans></span>
                                  </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade up small>
                  <div className="col-lg-6 item-cont">
                    <div className="card">
                      <div className="content">
                        <div className="front">
                          <img src="assets/icons/marketing.svg" />
                          <h3>
                            <Trans>text07</Trans>
                          </h3>
                        </div>
                        <div className="back">
                          <p>
                            <Trans>{marketingData.par}</Trans>
                          </p>
                          <h3>
                            <span>
                              <Trans>{marketingData.head}</Trans>
                            </span>
                          </h3>
                          <ul>
                            {marketingData.list.map((txt, i) => (
                              <li key={i}>
                                <div>
                                  <span className="square"></span>
                                  <span><Trans>{txt}</Trans></span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade up small>
                  <div className="col-lg-6 item-cont">
                    <div className="card">
                      <div className="content">
                        <div className="front">
                          <img src="assets/icons/studies.svg" />
                          <h3>
                            <Trans>text08</Trans>
                          </h3>
                        </div>
                        <div className="back">
                          <p>
                            <Trans>{studies.par}</Trans>
                          </p>
                          <h3>
                            <span>
                              <Trans>{studies.head}</Trans>
                            </span>
                          </h3>
                          <ul>
                            {studies.list.map((txt, i) => (
                              <li key={i}>
                                <div>
                                  <span className="square"></span>
                                  <span><Trans>{txt}</Trans></span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              {/* <div className="col-md-1"></div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* <div className="col-lg-5">
                <div className="thumbnail">
                  <Fade up small>
                    <img
                      className="w-100"
                      src={images[0].url}
                      alt="About Images"
                    />
                  </Fade>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner pt--60">
                  <div className="section-title">
                    <Fade up small>
                      <h2 className="title">real estate development</h2>
                      <p className="description">
                        The development arm of First Avenue provides development
                        services & solutions to its clients across commercial
                        and residential sectors. Our services include
                      </p>
                    </Fade>
                  </div>
                  <div className="single-tab-content">
                    <div className="mt--30">
                      <Fade up small>
                        <h4>Our Services Includes:</h4>
                        <ul className="list-style--1">
                          {development.map((name, index) => {
                            return (
                              <li key={index}>
                                <FiCheck /> {name}
                              </li>
                            );
                          })}
                        </ul>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div> */

/* <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-7">
                <div className="about-inner inner pt--60">
                  <div className="section-title">
                    <Fade up small>
                      <h2 className="title">real estate development Management & Advisory</h2>
                      <p className="description">
                      Using several years of experience, we manage development projects as if they were our own and provide advisory services to assist clients complete their projects successfully
                      </p>
                    </Fade>
                  </div>
                  <div className="mt--30">
                    <Fade up small>
                      <h4>Our Offering Includes: </h4>
                      <ul className="list-style--1">
                        {management.map((name, index) => {
                          return (
                            <li key={index}>
                              <FiCheck /> {name}
                            </li>
                          );
                        })}
                      </ul>
                    </Fade>
                  </div>
                  <div className="single-tab-content">
                    <Fade up small>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Fade up small>
                    <img
                      className="w-100"
                      src={images[1].url}
                      alt="About Images"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={images[2].url}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner pt--60">
                  <div className="section-title">
                    <Fade up small>
                      <h2 className="title">Real Estate Marketing</h2>
                      <p className="description">
                        First Avenue delivers marketing strategy to residential
                        and commercial real estate client.
                      </p>
                    </Fade>
                  </div>
                  <div className="single-tab-content">
                    <div className="mt--30">
                      <Fade up small>
                        <h4>Our Services Includes:</h4>
                        <ul className="list-style--1">
                          {marketing.map((name, index) => {
                            return (
                              <li key={index}>
                                <FiCheck /> {name}
                              </li>
                            );
                          })}
                        </ul>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-7">
                <div className="about-inner inner pt--60">
                  <div className="section-title">
                    <Fade up small>
                      <h2 className="title">High and Best use Studies</h2>
                      <p className="description">
                        First Avenue is providing rea l estate solutions for
                        sales and exchanges for private, public and
                        institutional owners/managers of commercial investment
                        real estate
                      </p>
                    </Fade>
                  </div>
                  <div className="single-tab-content">
                    <Fade up small>
                      <p>
                      
                      </p>
                    </Fade>

                    <div className="mt--30">
                      <Fade up small>
                        <h4>Our Services Includes:</h4>
                        <ul className="list-style--1">
                          {studies.map((name, index) => {
                            return (
                              <li key={index}>
                                <FiCheck /> {name}
                              </li>
                            );
                          })}
                        </ul>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Fade up small>
                    <img
                      className="w-100"
                      src={images[3].url}
                      alt="About Images"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */
