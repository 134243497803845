import React from "react";
import Team from "./TeamTwo";
import Title from "../Title";
import Fade from "react-reveal/Fade";
export default function Teams() {
  return (
    <div className="rn-team-wrapper py-5 bg_color--1">
      <div className="rn-team-area">
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <Fade up small>
                  <Title title="Our Leadership" />
                </Fade>
              </div>
            </div>
          </div> */}
          <Fade up small>
            <Team
              // column="col-lg-3 sm-12 md-6"
              teamStyle="team-style--bottom mb-4"
              item="8"
            />
          </Fade>
        </div>
      </div>
    </div>
  );
}
