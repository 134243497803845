import React from "react";
import Layout from "../components/Utils/Layout";
import headerImage from "../assets/Images/bg-image-18.jpeg";
import Title from "../components/Common/Title";
import "./ExperiencePage.scss";
import { ProjectList } from "../data/ProjectList";
import { ProgressBar } from 'react-bootstrap';
import PortfolioList from '../components/Portfolio/PortfolioList'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

export default function ExperiencePage() {
  return (
    <Layout>
      <div>
        <div className="">
          <img
            src={headerImage}
            alt="Header"
            style={{ width: "100%", opacity: 1, height: "500px" }}
          />
          <div className="about-wrapper ptb--60">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="about-inner inner text-center">
                    <div className="section-title">
                      <Title title={"Projects"} />
                      <p className="descrition">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti consequatur labore corrupti ab sit eligendi quaerat rem similique quia a autem alias natus repudiandae expedita officia, ratione laboriosam minima quam. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius eaque magnam laudantium nobis fuga debitis maxime repudiandae iste, culpa recusandae aut ad corporis quia perspiciatis cumque. Minus molestias numquam tempora!
                      </p>
                      <p className="descrition">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti consequatur labore corrupti ab sit eligendi quaerat rem similique quia a autem alias natus repudiandae expedita officia, ratione laboriosam minima quam. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius eaque magnam laudantium nobis fuga debitis maxime repudiandae iste, culpa recusandae aut ad corporis quia perspiciatis cumque. Minus molestias numquam tempora!
                      </p>
                    </div>

                    {/* Start Portfolio Area */}
                    <div className="portfolio-area ptb--60 bg_color--1">
                      <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                <h2 className="title">Our Latest Project</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <PortfolioList styevariation="text-center mt--40" column="col-lg-6 col-md-6 col-sm-6 col-12" item="4" />
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Portfolio Area */}


                    {/* Start About Area  */}
                    <div className="rn-about-area ptb--120 bg_color--1">
                      <div className="rn-about-wrapper">
                        <div className="container">
                          <div className="row row--35 align-items-center">
                            <div className="col-lg-7">
                              <div className="about-inner inner">
                                <div className="section-title">
                                  <h2 className="title">Lorem Ipsum</h2>
                                  <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p>
                                </div>
                                <div className="row mt--30">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="about-us-list">
                                      <h3 className="title">Our Working Process.</h3>
                                      <div className="rn-progress-bar progress-bar--3">
                                        <div className="single-progress custom-color--1">
                                          <h6 className="title">Designing</h6>
                                          <ProgressBar now={81} />
                                          <span className="label">81%</span>
                                        </div>

                                        <div className="single-progress custom-color--2">
                                          <h6 className="title">Managment</h6>
                                          <ProgressBar now={72} />
                                          <span className="label">72%</span>
                                        </div>

                                        <div className="single-progress custom-color--3">
                                          <h6 className="title">Marketing</h6>
                                          <ProgressBar now={89} />
                                          <span className="label">89%</span>
                                        </div>

                                        <div className="single-progress custom-color--4">
                                          <h6 className="title">Development</h6>
                                          <ProgressBar now={57} />
                                          <span className="label">57%</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-5 mt_md--40 mt_sm--40">
                              <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End About Area  */}

                    {/* Start About Area  */}
                    <div className="rn-about-area ptb--120 bg_color--1">
                      <div className="rn-about-wrapper">
                        <div className="container">
                          <div className="row row--35 align-items-center">
                            <div className="col-lg-5 mt_md--40 mt_sm--40">
                              <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" />
                              </div>
                            </div>
                            <div className="col-lg-7">
                              <div className="about-inner inner">
                                <div className="section-title">
                                  <h2 className="title">Lorem Ipsum</h2>
                                  <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p>
                                </div>
                                <div className="row mt--30">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="about-us-list">
                                      <h3 className="title">Our Working Process.</h3>
                                      <div className="rn-progress-bar progress-bar--3">
                                        <div className="single-progress custom-color--1">
                                          <h6 className="title">Designing</h6>
                                          <ProgressBar now={81} />
                                          <span className="label">81%</span>
                                        </div>

                                        <div className="single-progress custom-color--2">
                                          <h6 className="title">Managment</h6>
                                          <ProgressBar now={72} />
                                          <span className="label">72%</span>
                                        </div>

                                        <div className="single-progress custom-color--3">
                                          <h6 className="title">Marketing</h6>
                                          <ProgressBar now={89} />
                                          <span className="label">89%</span>
                                        </div>

                                        <div className="single-progress custom-color--4">
                                          <h6 className="title">Development</h6>
                                          <ProgressBar now={57} />
                                          <span className="label">57%</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End About Area  */}

                    <div className="row ptb--60">
                      {ProjectList &&
                        ProjectList.map((index) => (
                          <div
                            className="col-lg-4"
                            style={{ padding: "20px" }}
                            onClick={() => {
                              window.location.pathname = `/product/${index.id}`;
                            }}
                          >
                            <div className="card">
                              <img
                                src={index.images[0].url}
                                alt="Avatar"
                                className="image"
                                key={index.id}
                              />
                              <div className="overlay">
                                <div className="text">{index.name}</div>
                              </div>
                            </div>
                          </div>
                          // </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
      </div>
    </Layout>
  );
}
