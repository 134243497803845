import React from "react";
import "./About.css";
import CounterOne from "./CounterOne";
import Title from "./Title";
import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";

export default function About() {
  return (
    <div className="rn-about-area ptb--60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
              <Fade up small>
                <Title title={"Who Are We?"} />
              </Fade>
              <Fade up small>
                {" "}
                <p>
                  <Trans>AboutPar</Trans>
                </p>
              </Fade>
            </div>
          </div>
        </div>

        <CounterOne />
      </div>
    </div>
  );
}
