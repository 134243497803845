import React from "react";
// import Experience from "../components/Portfolio/Experience";
import Breadcrumb from "../components/Common/Breadcrumb";
import AboutUs from "../components/Common/About/AboutUs";
import Layout from "../components/Utils/Layout";
import TeamDetails from "../components/Common/Team/TeamDetails";
// import Brands from "../components/Common/Clients/Brands";
import Teams from "../components/Common/Team/Team";

export default function AboutPage() {
  return (
    <Layout>
      <Breadcrumb title={"About Us"} />
      <AboutUs />
      {/* <Experience /> */}
      <TeamDetails />
      <Teams/>
      {/* <Brands /> */}
    </Layout>
  );
}
