import React from "react";
import data from "./data";
import "./TeamButton.scss";
import { Trans } from "react-i18next";

const Team = (props) => {
  const itemSlice = data.slice(0, props.item);
  return (
    <div className="row">
      {itemSlice.map((value, i) => (
        <div className="col-lg-3 col-md-6 col-sm-12 text-center" key={i}>
          <div className={` ${props.teamStyle}`}>
            <div className="thumbnail">
              <img
                src={value.images}
                alt="Blog Images"
                style={{ opacity: 1, left: "50%" }}
              />
            </div>
            <div className="inner">
              <div className="content">
                <h4 className="title">
                  <Trans>{value.title}</Trans>
                </h4>
                <p className="designation">
                  <Trans>{value.designation}</Trans>
                </p>
              </div>
              {/* <ul className="social-transparent liststyle d-flex" >
                {value.socialNetwork.map((social, index) =>
                  <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                )}
              </ul> */}
            </div>
          </div>
        </div>
      ))}
      {/* <div
        className="col-lg-3 col-md-6 col-sm-12"
        style={{ verticalAlign: "middle" }}
      >
        <a className="horizontal" href="/">
          <span className="text">Know More &#8594;</span>
        </a>
      </div> */}
    </div>
  );
};
export default Team;
