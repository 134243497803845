import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import Layout from "../components/Utils/Layout";
// import ServicesTab from "../components/Common/Service/ServicesTab";
// import { FiCheck } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import Title from "../components/Common/Title";
import Experience from "../components/Portfolio/ExperienceRiyadh";
import CounterOne from "../components/Common/CounterRiyadh";
// import development from "/assets/images/servicepage/development.jpg";

export default function RiyadhPage() {
  const images = {
    url: process.env.PUBLIC_URL + "/assets/images/riyadh/riyadh-breadcrumb.jpg",
  };
  return (
    <Layout>
      <Breadcrumb title={"Riyadh"} image={images} />
      {/* <div className="section-title text-center mt--30 mb--30">
        <Fade up small>
          <Title title={"Riyadh"} />
        </Fade>
      </div> */}
      <div className="rn-service-details ptb--60 bg_color--1">
        <div className="container">
          <CounterOne />
        </div>
        <Experience />
      </div>
    </Layout>
  );
}
