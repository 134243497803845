import React from "react";
import Fade from "react-reveal/Fade";
// import PortfolioList from "./PortfolioList";
import Gallery from "../Gallery";
import Title from "../Common/Title";
// import { ProjectList } from "../../data/ProjectList";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function Experience() {
  // const TabOne = [
  //   {
  //     image: "01",
  //     bigImage: "/assets/images/Projects/AlGhadeerMain.jpg",
  //     category: "Completed",
  //     title: "Al Ghadeer",
  //   },
  //   {
  //     image: "02",
  //     bigImage: "/assets/images/Projects/AlMajdiyahmain.jpg",
  //     category: "Completed",
  //     title: "Al Majidiyah",
  //   },
  //   {
  //     image: "03",
  //     bigImage: "/assets/images/Projects/CommercialAvenueMain.jpg",
  //     category: "Completed",
  //     title: "The Commercial Avenue",
  //   },
  //   {
  //     image: "04",
  //     bigImage: "/assets/images/Projects/OfficeAvenueMain.jpg",
  //     category: "Mobile App",
  //     title: "The Office Avenue",
  //   },
  //   {
  //     image: "05",
  //     bigImage: "/assets/images/Projects/SariHotelMain.png",
  //     category: "Completed",
  //     title: "Sari Hotel",
  //   },
  //   {
  //     image: "06",
  //     bigImage: "/assets/images/Projects/AlJawarah.jpg",
  //     category: "Pipeline",
  //     title: "Al Jawarah",
  //   },
  // ];

  const TabOne = [
    {
      image: "02",
      bigImage: "/assets/images/home-experience/al-ghadeer-avenue.JPG",
      category: "Ongoing",
      title: "Al-Ghadeer Avenue",
    },
    {
      image: "03",
      bigImage: "/assets/images/slider/Home_2.jpg",
      category: "Ongoing",
      title: "Jasmin Avenue",
    },
    {
      image: "04",
      bigImage: "/assets/images/home-experience/al-qirawan-gate.jpeg",
      category: "Ongoing",
      title: "Al-Qirawan Gate",
    },
    {
      image: "05",
      bigImage: "/assets/images/home-experience/the-boutique-office.JPG",
      category: "Completed",
      title: "The Boutique Office",
    },
    {
      image: "06",
      bigImage: "/assets/images/riyadh/8.jpg",
      category: "Completed",
      title: "Almalqa Avenue",
    },
    {
      image: "07",
      bigImage: "/assets/images/riyadh/9.jpeg",
      category: "Ongoing",
      title: "Hitten Offices",
    },
  ];
  return (
    // <div id="portfolio" className="fix">
    //   <div className="portfolio-area ptb--60 bg_color--5">
    //     <div className="portfolio-sacousel-inner">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-12">
    //             <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
    //               <Fade up small>
    //                 <Title title="Our Experience" />
    //                 <p>
    //                 Our team of professionals has a distinguished history of handling real estate projects. First Avenue has a rich expeience in real estate development and High and Best Use advisory projects across a wide variety of property types in Riyadh and Jeddah.
    //                 </p>
    //               </Fade>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="row">
    //           <Fade up small>
    //             <Gallery data={TabOne} />
    //           </Fade>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container text-center ">
      <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
        <Title title="Our Experience" />
      </div>

      <div className="section-title service-style--3">
        <Fade up small>
          <p className="mt-4 brown-color mb-5 font-weight-light">
            <Trans>p6</Trans>
          </p>
        </Fade>
      </div>
      
      <div className="row">
        <div className="col-md-6 mb-3">
        <Link to="/experience-riyadh" className="blend-item green">
        <img src="../../assets/images/RIYADH.png" />
            <span className="d-inline-block">
              <h3>
                <Trans>Riyadh</Trans>
              </h3>
              <p>
                <Trans>TheProjects</Trans>
              </p>
            </span>
        </Link>
          {/* <a class="blend-item green" href="/experience-riyadh">
            <img src="../../assets/images/RIYADH.png" />
            <span className="d-inline-block">
              <h3>
                <Trans>Riyadh</Trans>
              </h3>
              <p>
                <Trans>TheProjects</Trans>
              </p>
            </span>
          </a> */}
        </div>
        <div className="col-md-6 mb-3">
          <Link to="/experience-jeddah" className="blend-item green">
          <img src="../../assets/images/JEDDAH.png" />
            <span className="d-inline-block">
              <h3 className="text-uppercase">
                <Trans>Jeddah</Trans>
              </h3>
              <p>
                <Trans>TheProjects</Trans>
              </p>
            </span>
          </Link>
          {/* <a class="blend-item green" href="/experience-jeddah">
            <img src="../../assets/images/JEDDAH.png" />
            <span className="d-inline-block">
              <h3 className="text-uppercase">
                <Trans>Jeddah</Trans>
              </h3>
              <p className="text-uppercase">
                <Trans>TheProjects</Trans>
              </p>
            </span>
          </a> */}
        </div>
      </div>
    </div>
  );
}
