import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import Layout from "../components/Utils/Layout";
import { Trans } from "react-i18next";

// import headerImage from "../assets/Images/bg-image-18.jpeg";
// import Title from "../components/Common/Title";
import "./ExperiencePage.scss";
// import CountUp from 'react-countup';
// import VisibilitySensor from 'react-visibility-sensor';
// import { useState } from "react";
// import ServiceList from '../components/Common/Service/ServiceList';
// import { FiCast, FiLayers, FiUsers } from "react-icons/fi";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
// import {Redirect} from 'react-router-dom'

export default function InvestorRelationPage() {
  // let Data = [
  //   {
  //     countNum: 199,
  //     countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
  //   },
  //   {
  //     countNum: 575,
  //     countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
  //   },
  //   {
  //     countNum: 69,
  //     countTitle: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those.',
  //   },
  // ];

  // const ServiceList = [
  //   {
  //     icon: <FiCast />,
  //     title: "Annual Meeting",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },
  //   {
  //     icon: <FiLayers />,
  //     title: "Company Policies",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   },
  //   {
  //     icon: <FiUsers />,
  //     title: "Announcements",
  //     description:
  //       "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  //   }
  // ];

  // const SocialShare = [
  //   { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  //   { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  //   { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  //   { Social: <FaTwitter />, link: 'https://twitter.com/' },
  // ]
  // const [didViewCountUp, setdidViewCountUp] = useState(false)

  // const onVisibilityChange = isVisible => {
  //   if (isVisible) {
  //     setdidViewCountUp(true);
  //   }
  // }
  const image = {
    url: process.env.PUBLIC_URL + "/assets/images/investors/breadcrumb.png",
  };
  return (
    <Layout>
      <Breadcrumb title={"InvestorRelations"} image={image} />
          <div className="about-wrapper ptb--60">
            <div className="row row--35 align-items-center">
              <div className="col-lg-12 col-md-12">
                {/* <div className="about-inner inner text-center">
                  <div className="section-title">
                    <Title title={"Why Invest With First Avenue"} />
                  </div>
                </div> */}
              </div>

              <div className="col-lg-12 col-md-12 mtb--60">
                {/* <div className="about-inner inner text-center">
                  <div className="section-title">
                    <Title title={"Financial Information"} />
                  </div>
                </div> */}
                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="portfolio-details text-center">
                          <div className="inner ">
                            <h2 className="green-color">
                              <Trans>
                              text16
                              </Trans>
                            </h2>
                            {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                            <p>
                              <Trans>text02</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container competitive-advantages">
                  <div className="row">
                    <div className="col-12 col-md-4 d-flex align-items-center justify-content-center pr-md-0">
                      <h3 className="green-color mb-4 mb-md-0">
                        <Trans>text03</Trans>
                      </h3>
                    </div>
                    <div className="col-12 col-md-4 pr-md-0">
                      <div className="item">
                        <img src="assets/icons/lamp-white.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>Creative</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>Ideas</Trans>
                          </span>
                        </h4>
                      </div>
                      <div className="item">
                        <img src="assets/icons/stars.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>Excellent</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>Reputation</Trans>
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="item">
                        <img src="assets/icons/Banking.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>BankingInvestment</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>Relations</Trans>
                          </span>
                        </h4>
                      </div>
                      <div className="item">
                        <img src="assets/icons/Experience.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>Cumulative</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>Experience</Trans>
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 p-0"></div>

                    <div className="col-12 col-md-4 pr-md-0">
                      <div className="item">
                        <img src="assets/icons/Cost.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>text39</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>text40</Trans>
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 pr-md-0">
                      <div className="item">
                        <img src="assets/icons/Partnerships.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>text33</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>text34</Trans>
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="item">
                        <img src="assets/icons/Execution.svg" alt="" />
                        <h4>
                          <span className="d-block">
                            <Trans>text35</Trans>
                          </span>
                          <span className="d-block">
                            <Trans>text36</Trans>
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

             

                {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
                  <div className="inner">
                    <div className="content-wrapper">
                      <div className="content">
                        <h4 className="theme-gradient">
                          Financial Information
                        </h4>
                        <br />
                        <a
                          className="rn-btn"
                          href="/assets/documents/2020_Financial_Statements.pdf"
                          download="financial_statement_2020"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div className="thumbnail">
                      <div className="image">
                        <img
                          height="500"
                          width="1300"
                          style={{ zIndex: "0" }}
                          src={"/assets/images/investor-relations.jpg"}
                          alt="Finding Images"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="container">
                  <div className="financial-info">
                    <div className="app-overlay green"></div>
                    <img
                      style={{ zIndex: "0" }}
                      src={"/assets/images/Financial Information.png"}
                      alt="Finding Images"
                    />

                    <div className="content">
                      <h4>
                        <span>
                          <Trans>text37</Trans>
                        </span>
                        <span>
                          <Trans>text38</Trans>
                        </span>
                      </h4>
                      <a
                        className="btn"
                        href="/assets/documents/2020_Financial_Statements.pdf"
                        download="financial_statement_2020"
                      >
                        <Trans>Download</Trans>
                      </a>
                    </div>
                  </div>
                </div>
    </Layout>
  );
}
