import React from "react";
import Fade from "react-reveal/Fade";
import Gallery from "../Gallery";
import Title from "../Common/Title";

export default function Experience() {
  const TabOne = [
    {
        image: "01",
        bigImage: "/assets/images/jeddah/15.jpg",
        category: "Ongoing",
        title: "text25",
      },
      {
        image: "02",
        bigImage: "/assets/images/jeddah/2.jpg",
        category: "Ongoing",
        title: "text26",
      }
  ];
  return (
    <div id="portfolio" className="fix">
      <div className="portfolio-area ptb--60">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <Fade up small>
                    <Title title="Projects in Jeddah" />
                   
                  </Fade>
                </div>
              </div>
            </div> */}
           
            <Fade up small>
              <Gallery data={TabOne} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
