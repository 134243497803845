import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

class Breadcrumb extends Component {
  render() {
    const { title, parent } = this.props;
    return (
      <React.Fragment>
        <div
          className="breadcrumb-area rn-bg-color ptb--60 bg_image position-relative d-flex align-items-center "
          style={{
            backgroundImage:
              this.props.image !== undefined
                ? `url(${this.props.image.url})`
                : "url(/assets/images/Projects/AlMajdiyahmain.jpg)",
          }}
          // data-black-overlay="6"
        >
          <div className="app-overlay green"></div>
          <div className="app-overlay blur"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner">
                  <h2 className="title">
                    <span>
                      <Trans>{title}</Trans>
                    </span>
                  </h2>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Breadcrumb;



//<ul className="page-list">
//   {/* <li className="breadcrumb-item">
//                  <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
//              </li> */}
//            {/* {parent ? (
//            <li className="breadcrumb-item">{parent}</li>
//        ) : (
//        ""
//    )}
//  <li className="breadcrumb-item active">{title}</li> */}
//  </ul>
