import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      // {
      //   countNum: 120,
      //   suffix:"k",
      //   countTitle: "sqm of real estate & land valuation across the Kingdom",
      // },
      {
        countNum: 1.6,
        suffix:"Billion",
        countTitle: "text10",
      },
      {
        countNum: 400,
        suffix:"K",
        countTitle: "text11",
      },
      {
        countNum: 25,
        suffix:"",
        countTitle: "text12",
      },
    ];

    return (
      <Fragment>
        <div className="row counter-cont">
          {Data.map((value, index) => (
            <div
              className={'counterup_style--2 col-lg-4 col-md-4 col-12 mb-4 mb-md-3 item-' + index}
              key={index}
            >
              <h5 className="counter mb-2 mb-md-4">
                <span>+</span>
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0} decimals={index == 0 ? 1 : 0}
                    decimal="."
                  />
                </VisibilitySensor>
                <span>
                  <Trans>
                  {value.suffix}
                  </Trans>
                </span>
              </h5>
              <Fade up small>
                <p className="description">
                  <Trans>
                  {value.countTitle}
                  </Trans>
                </p>
              </Fade>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
